<template>
  <b-tabs
    pills
    nav-wrapper-class="col-12"
    content-class="col-12 col-md-12 mt-1 mt-md-0"
  >
    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('General') }}</span>
      </template>

      <user-edit-general
        v-if="options.general"
        :general-data="options.general"
        @update-user-edit="updateUserEdit"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- permission tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="KeyIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('Permission') }}</span>
      </template>

      <user-edit-permission
        v-if="options.permission"
        :permission-data="options.permission"
        @update-user-edit="updateUserEdit"
      />
    </b-tab>
    <!--/ permission tab -->

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('Change Password') }}</span>
      </template>

      <user-edit-password />
    </b-tab>
    <!--/ change password tab -->

    <!-- info -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('Information') }}</span>
      </template>

      <user-edit-information
        v-if="options.info"
        :information-data="options.info"
        @update-user-edit="updateUserEdit"
      />
    </b-tab>

    <!-- social links -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LinkIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('Social') }}</span>
      </template>

      <user-edit-social
        v-if="options.social"
        :social-data="options.social"
        @update-user-edit="updateUserEdit"
      />
    </b-tab>

    <!-- notification -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="BellIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('Notifications') }}</span>
      </template>

      <user-edit-notification
        v-if="options.notification"
        :notification-data="options.notification"
        @update-user-edit="updateUserEdit"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import UserEditGeneral from './UserEditGeneral.vue'
import UserEditPermission from './UserEditPermission.vue'
import UserEditPassword from './UserEditPassword.vue'
import UserEditInformation from './UserEditInformation.vue'
import UserEditSocial from './UserEditSocial.vue'
import UserEditNotification from './UserEditNotification.vue'
import userStoreModule from '../userStoreModule'

export default {
  components: {
    BTabs,
    BTab,
    UserEditGeneral,
    UserEditPermission,
    UserEditPassword,
    UserEditInformation,
    UserEditSocial,
    UserEditNotification,
  },
  data() {
    return {
      options: {},
    }
  },
  mounted() {
    store
      .dispatch('user-store/getUser', { id: router.currentRoute.params.id })
      .then(response => {
        const okMsg = this.$i18n.t('Process on successfully')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: okMsg,
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        },
        {
          position: 'bottom-right',
        })
        this.options = response.data
      })
      .catch(error => {
        const { response, message } = error
        if (response) {
          const errorMsg = this.$i18n.t(response?.data.error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorMsg,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          },
          {
            position: 'bottom-right',
          })
        } else if (message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error?.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          },
          {
            position: 'bottom-right',
          })
        }
      })
  },
  methods: {
    updateUserEdit(data) {
      this.options = data
    },
  },
  setup() {
    const STORE_MODULE_NAME = 'user-store'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, userStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    return {}
  },
}
</script>
