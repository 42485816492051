<template>
  <b-card>

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >

      <!-- form -->
      <b-form
        ref="refForm"
        class="mt-1"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetLocalForm"
      >

        <!-- Header: Personal Info -->
        <div class="d-flex mb-1">
          <feather-icon
            icon="InfoIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            {{ $t('Personal Information') }}
          </h4>
        </div>

        <b-row class="mt-1">
          <!-- bio -->
          <b-col cols="12">
            <b-form-group
              :label="$t('Bio')"
              label-for="account-bio"
            >
              <b-form-textarea
                id="account-bio"
                v-model="localData.bio"
                rows="3"
                :placeholder="$t('Your bio data here')"
              />
            </b-form-group>
          </b-col>
          <!--/ bio -->

          <!-- birth date -->
          <b-col md="6">
            <b-form-group
              label-for="account-dob"
              :label="$t('Birth date')"
            >
              <flat-pickr
                id="account-dob"
                v-model="localData.dob"
                :config="{ dateFormat: 'd F Y' }"
                class="form-control"
                :placeholder="$t('Birth date')"
              />
            </b-form-group>
          </b-col>
          <!--/ birth date -->

          <!-- Country -->
          <b-col md="6">
            <b-form-group
              label-for="account-country"
              :label="$t('Country')"
            >
              <v-select
                id="account-country"
                v-model="localData.country"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="countryOption"
              />
            </b-form-group>
          </b-col>
          <!--/ Country -->

          <!-- website -->
          <b-col md="6">
            <b-form-group
              label-for="account-website"
              :label="$t('Website')"
            >
              <validation-provider
                #default="validationContext"
                :name="$t('Website')"
              >
                <b-form-input
                  id="account-website"
                  v-model="localData.website"
                  :placeholder="$t('Website')"
                  :state="getValidationState(validationContext)"
                  :disabled="busy"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>

            </b-form-group>
          </b-col>
          <!--/ website -->

          <!-- phone -->
          <b-col md="6">
            <b-form-group
              label-for="account-mobile"
              :label="$t('Mobile')"
            >
              <cleave
                id="account-mobile"
                v-model="localData.mobile"
                class="form-control"
                :raw="false"
                :options="clevePhone"
                :placeholder="$t('Mobile number')"
              />
            </b-form-group>
          </b-col>
          <!-- phone -->

          <!-- Language -->
          <b-col md="6">
            <b-form-group
              :label="$t('Language')"
              label-for="account-language"
            >
              <v-select
                id="account-language"
                v-model="localData.language"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="languageOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="language"
              />
            </b-form-group>
          </b-col>
          <!--/ Language -->

          <!-- Gender -->
          <b-col md="6">
            <b-form-group
              :label="$t('Gender')"
              label-for="gender"
              label-class="mb-1"
            >

              <validation-provider
                #default="{ errors }"
                :name="$t('Gender')"
                rules="required"
              >
                <b-form-radio-group
                  id="gender"
                  v-model="localData.gender"
                  :options="genderOptions"
                  value="male"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ Gender -->

        </b-row>

        <!-- Header: Location -->
        <div class="d-flex mt-2 mb-1">
          <feather-icon
            icon="MapPinIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            {{ $t('Location') }}
          </h4>
        </div>

        <b-row class="mt-1">

          <!-- Field: Address Line 1 -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              :label="`${$t('Address Line')} 1`"
              label-for="location-address-line-1"
            >
              <b-form-input
                id="location-address-line-1"
                v-model="localData.location.add_line_1"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Address Line 2 -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              :label="`${$t('Address Line')} 2`"
              label-for="address-line-2"
            >
              <b-form-input
                id="address-line-2"
                v-model="localData.location.add_line_2"
                placeholder=""
              />
            </b-form-group>
          </b-col>

          <!-- Field: Postcode -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              :label="$t('Postcode')"
              label-for="location-postcode"
            >
              <b-form-input
                id="location-postcode"
                v-model="localData.location.post_code"
                type="number"
                :placeholder="$t('Postcode')"
              />
            </b-form-group>
          </b-col>

          <!-- Field: City -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              :label="`${$t('City')}/${$t('District')}`"
              label-for="location-city"
            >
              <b-form-input
                id="location-city"
                v-model="localData.location.city"
                :placeholder="`${$t('City')}/${$t('District')}`"
              />
            </b-form-group>
          </b-col>

          <!-- Field: State -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              :label="`${$t('State')}/${$t('Province')}`"
              label-for="location-state"
            >
              <b-form-input
                id="location-state"
                v-model="localData.location.state"
                :placeholder="`${$t('State')}/${$t('Province')}`"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Country -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              :label="$t('Country')"
              label-for="location-country"
            >
              <v-select
                id="location-country"
                v-model="localData.location.country"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="countryOption"
                :placeholder="$t('Country')"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="8">
            <b-button
              ref="submit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              :disabled="busy"
              @click="onSubmit"
            >
              {{ $t('Save changes') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="resetLocalForm"
            >
              {{ $t('Reset') }}
            </b-button>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="text-right d-none d-md-block d-lg-block"
          >
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-primary"
              type="button"
              class="mt-2"
              @click.prevent="gotoUserList"
            >
              <feather-icon
                icon="ChevronLeftIcon"
                size="12"
              />
              <span class="ml-25 align-middle"> {{ `${$t('Goto')} ${$t('User List')}` }}</span>
            </b-button>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="d-lg-none d-md-none d-sm-block"
          >
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-primary"
              type="button"
              class="mt-2"
              @click.prevent="gotoUserList"
            >
              <feather-icon
                icon="ChevronLeftIcon"
                size="12"
              />
              <span class="ml-25 align-middle"> {{ `${$t('Goto')} ${$t('User List')}` }}</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>

    </validation-observer>

    <b-overlay
      :show="busy"
      no-wrap
      @shown="onShown"
      @hidden="onHidden"
    >
      <template #overlay>
        <div
          v-if="processing"
          class="text-center p-2 bg-primary text-light rounded"
        >
          <feather-icon
            icon="UploadCloudIcon"
            size="20"
          />
          <div class="mb-2">
            {{ `${$t('Processing')}...` }}
          </div>
          <b-progress
            min="1"
            max="10"
            :value="counter"
            variant="success"
            height="6px"
            class="mx-n1"
          />
        </div>
        <div
          v-else
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <b-card-text
            class="font-weight-bolder"
          >
            {{ $t('Are you sure?') }}
          </b-card-text>
          <div class="d-flex">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mr-3"
              @click="onCancel"
            >
              {{ $t('Cancel') }}
            </b-button>
            <b-button
              ref="dialogOk"
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="outline-success"
              @click="onOK"
            >
              {{ $t('Ok') }}
            </b-button>
          </div>
        </div>
      </template>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BFormTextarea,
  BOverlay,
  BProgress,
  BCardText,
  BFormRadioGroup,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import {
  onUnmounted,
} from '@vue/composition-api'
import {
  ValidationProvider, ValidationObserver,
} from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import formValidation from '@core/comp-functions/forms/form-validation'
import { t } from '@/@core/libs/i18n/utils'
import userStoreModule from '../userStoreModule'

// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    vSelect,
    flatPickr,
    Cleave,
    BOverlay,
    BProgress,
    BFormInvalidFeedback,
    BCardText,
    BFormRadioGroup,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    informationData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      countryOption: ['Thailand'],
      localData: JSON.parse(JSON.stringify(this.informationData)),
      userData: JSON.parse(localStorage.getItem('qtw-user-data')),
      clevePhone: {
        phone: true,
        phoneRegionCode: 'TH',
      },
      required,
      busy: false,
      processing: false,
      counter: 1,
      interval: null,
    }
  },
  mounted() {
    this.$refs.submit.focus()
  },
  methods: {
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialogOk.focus()
    },
    onHidden() {
      // In this case, we return focus to the submit button
      // You may need to alter this based on your application requirements
      this.$refs.submit.focus()
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          this.processing = false
          this.busy = true
        }
      })
    },
    onCancel() {
      this.busy = false
    },
    onOK() {
      this.processing = true
      store.dispatch('user-store/editUser', { id: this.$router.currentRoute.params.id, data: this.localData })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.busy = false
          this.processing = false
          this.$emit('update-user-edit', response.data)
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            let errorMsg = this.$i18n.t(response?.data.error)
            if (errorMsg === '') {
              errorMsg = this.$i18n.t(response?.data.message)
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.busy = false
          this.processing = false
        })

      this.$refs.submit.focus()
    },
    resetLocalForm() {
      this.localData = JSON.parse(JSON.stringify(this.informationData))
      this.$refs.refFormObserver.reset()
      this.$refs.submit.focus()
    },
    gotoUserList() {
      this.$router.push({ name: 'user-list' })
    },
  },
  setup() {
    const STORE_MODULE_NAME = 'user-store'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const languageOptions = [
      { label: t('English'), value: 'english' },
      { label: t('Thai'), value: 'thai' },
    ]

    const genderOptions = [
      { text: t('Male'), value: 'male' },
      { text: t('Female'), value: 'female' },
    ]

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation()

    return {
      languageOptions,
      genderOptions,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div{
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>
